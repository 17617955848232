import { ABIAppButton } from "@ab-inbev-labs/ux-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const AppRouteNotFound = (props: { appTitle: string }) => {
  const i18next = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <h3>{i18next.t("pageNotFound")}</h3>
      <ABIAppButton
        className="page-not-found-button"
        onClick={() => navigate(window.location.pathname.split("/")[1])}
      >
        {`${i18next.t("returnTo")} ${props.appTitle}`}
      </ABIAppButton>
      <ABIAppButton onClick={() => window.location.assign("/apps")}>
        {i18next.t("returnToApps")}
      </ABIAppButton>
    </>
  );
};
