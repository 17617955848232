import React, { useState } from "react";

import "@ab-inbev/sam-utils/grid-layout-shared.scss";

import AppCard from "./AppCard";
import { ABISearchBar } from "@ab-inbev-labs/ux-react-components";
import { defaultRouteConfig } from "@ab-inbev/sam-layout";
import { useTranslation } from "react-i18next";
import "./App.scss";

import manifestFile from "../apps-manifest.json";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { FeatureFlagNames, useFeatureFlags } from "@ab-inbev/sam-utils";
import {
  ErrorBoundary,
  LayoutWithLoader,
  Root,
} from "@ab-inbev/sam-components";

type AppManifest = {
  title: Record<string, string | undefined>;
  description: Record<string, string | undefined>;
  path: string;
  cta: Record<string, string | undefined>;
};

export function AppFromManifest({ manifest }: { manifest: AppManifest[] }) {
  const i18next = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");

  const translate = (record: Record<string, string | undefined>) => {
    return record[i18next.i18n.language] ?? record["en-US"] ?? "";
  };

  const filteredCards = manifest.filter((card) => {
    return translate(card.title)
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  const appContent = (
    <div>
      <div className="apps-search-area">
        <ABISearchBar
          placeholder={i18next.t("searchApps")}
          onChange={setSearchTerm}
        />
      </div>
      <section className="grid-auto-col-count-min-width apps-grid">
        {filteredCards.map((card) => (
          <AppCard
            key={translate(card.title)}
            title={translate(card.title)}
            description={translate(card.description)}
            path={card.path}
            ctaLabel={translate(card.cta)}
          />
        ))}
      </section>
    </div>
  );

  const appRoutes = [
    {
      path: "/apps",
      element: <Root />,
      errorElement: (
        <LayoutWithLoader showUserProfile={false}>
          <ErrorBoundary />
        </LayoutWithLoader>
      ),
      children: [
        {
          index: true,
          element: appContent,
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    ...defaultRouteConfig(i18next.t("appName")),
    ...appRoutes,
  ]);

  return <RouterProvider router={router} />;
}

function App() {
  const appsManifest: AppManifest[] = manifestFile;

  const enableBrewingRecipe = useFeatureFlags(
    FeatureFlagNames.BREWING_RECIPE_APP_CARD,
  );
  let filteredManifest = appsManifest;

  //Remove Brewing Recipes card from env not set in feature flag
  //TODO remove this flag on release
  if (!enableBrewingRecipe) {
    filteredManifest = appsManifest.filter(
      (manifest) => manifest.title["en-US"] !== "Brewing Recipes",
    );
  }

  return <AppFromManifest manifest={filteredManifest} />;
}

export default App;
