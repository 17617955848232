import { useContext } from "react";
import { FeatureFlagContext } from "./FeatureFlagContext";
import { FeatureFlagNames } from "./FeatureFlagNames";

export const useFeatureFlags = (flagName?: FeatureFlagNames): boolean => {
  const { featureFlags } = useContext(FeatureFlagContext);

  if (featureFlags === undefined) {
    console.warn("useFeatureFlags must be used within a FeatureFlagProvider");
    return false;
  }

  if (flagName === undefined) {
    return false;
  }

  const flag = featureFlags.find((ff) => ff.name === flagName);

  return flag && flag.enabled ? flag.enabled : false;
};
