export enum FeatureFlagNames {
  ACTIVATE_SAVE_BREW_INFO = "ActivateSaveBrewInfo",
  BREW_CALCS_URL_UPDATE = "BrewCalcsURLUpdate",
  BREWING_RECIPE_APP_CARD = "BrewingRecipeAppCard",
  BREWING_SPECIALS_RTF = "BrewingSpecialsRTF",
  CAN_MANAGE_RECIPES = "CanManageRecipes",
  HOPS_CORPORATE_CHANGE = "HopsCorporateChange",
  HOPS_CREATE_SCRATCHPAD = "HopsCreateScratchPad",
  HOPS_IBU_CHANGE = "HopsIBUChange",
  HOPS_LOT_NUMBER_CROP_YEAR_CHANGE = "HopsLotNumberCropYearChange",
  HOPS_RECIPE_PROCEDURE_HISTORY = "HopsRecipeProcedureHistory",
  HOP_LOT_ADMIN = "HopLotAdmin",
  MOCK_RECIPE_API = "MockRecipeApi",
  LOT_NUMBER_SINGLE_BRAND = "Lot_Number_Single_Brand",
  MULTI_SELECT_VARIETIES_LOT_CHANGE = "MultiSelectVarietiesLotChange",
  PREVIEW = "Preview",
  USER_PROFILE_UNITS_SETTING = "UserProfileUnitsSetting",
  USER_PROFILE_NOTIFICATIONS_ENABLED = "UserProfileNotificationsEnabled",
  USER_PROFILE_NOTIFICATIONS_ADDITIONAL_OPTIONS = "UserProfileNotificationsAdditionalOptions",
  VIEW_ASSIGN_ACTIVE_LOT_NUMBERS = "ViewAssignActiveLotNumbers",
}
